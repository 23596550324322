import {registerVueControllerComponents} from '@symfony/ux-vue';
import './bootstrap.js';
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './css/app.css';
import "./services/ga";

registerVueControllerComponents(require.context('./vue/controllers', true, /\.vue$/));

import {createRouter, createWebHistory} from 'vue-router';

// Vuetify
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
});

document.addEventListener('vue:before-mount', (event) => {
    const {
        componentName, // The Vue component's name
        component, // The resolved Vue component
        props, // The props that will be injected to the component
        app, // The Vue application instance
    } = event.detail;

    // Example with Vue Router
    const router = createRouter({
        history: createWebHistory(),
        routes: [
            {
                path: '/compare/:slugs',
                name: componentName,
                component,
                props,
            },
        ],
    });

    app.use(router);
    app.use(vuetify);
});
import {Controller} from "@hotwired/stimulus";
import Swiper from 'swiper';
import 'swiper/scss';

export default class extends Controller {
    connect() {
        new Swiper(this.element, {
            slidesPerView: 2,
            breakpoints: {
                414: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                1280: {
                    slidesPerView: 5,
                },
                2400: {
                    slidesPerView: 7,
                },
                3500: {
                    slidesPerView: 9,
                },
                4500: {
                    slidesPerView: 11,
                },
                5500: {
                    slidesPerView: 13,
                },
            },
            centeredSlides: true,
            spaceBetween: 30,
            effect: 'slide',
        });
    }
}
import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['content', 'summary'];

    connect() {
        this.balisesHn = this.contentTarget.querySelectorAll('h1, h2, h3, h4, h5, h6');
        this.putSummary();
    }

    putSummary() {
        this.balisesHn.forEach((balise) => {
            let texteSlug = balise.textContent.toLowerCase().replace(/ /g, '-');
            balise.id = texteSlug;

            let lienSommaire = document.createElement('li');
            let lien = document.createElement('a');
            lien.textContent = balise.textContent;
            lien.href = '#' + texteSlug;
            lien.style.marginLeft = (Number(balise.tagName.match(/\d+/)) * 10) + 'px';
            lienSommaire.appendChild(lien);
            this.summaryTarget.appendChild(lienSommaire);
        });
    }
}

<template>
  <div class="flex justify-center items-center">
    <v-autocomplete
        label="Rechercher une carte graphique..."
        class="block hover:bg-transparent"
        variant="solo"
        :items="Object.keys(reac.autocompleteSearch)"
        @update:search="computed.getSearch"
        @update:modelValue="(e) => emit('submit', e, reac.autocompleteSearch[e])"
    />
  </div>
</template>

<script setup>
import {defineProps, onMounted, reactive} from "vue";
import fetchHardware from "@/vue/services/fetchHardware";

const props = defineProps({
  urlSearch: {
    type: String,
    required: true,
  },
})

const reac = reactive({
  autocompleteSearch: {},
})

onMounted(() => {
  computed.getSearch();
})

const emit = defineEmits(['submit']);

const computed = {
  async getSearch(e = '') {
    const response = await fetchHardware(props.urlSearch + e);
    reac.autocompleteSearch = await response.json();
  },
};
</script>
<template>
  <div class="relative w-full md:flex md:space-x-4 space-y-4 md:space-y-0">
    <div class="w-full">
      <div class="relative w-full flex space-x-2">
        <div class="w-full">
          <label for="table-search-carte" class="sr-only">Hashrate</label>
          <input type="number"
                 id="table-search-carte"
                 class="border text-sm focus:outline-none rounded-lg block w-full p-3 bg-gray-700 border-gray-600 placeholder-gray-400 text-white"
                 min="0"
                 v-model="obj.hash"
                 :placeholder="trans(HASHRATE_PLACEHOLDER)"
          >
        </div>
        <select name="hashType"
                id="hashType"
                class="border text-sm focus:outline-none rounded-lg block p-3 bg-gray-700 border-gray-600 placeholder-gray-400 text-white"
                v-model="obj.key"
        >
          <option value="1" selected="selected">H/s</option>
          <option value="100">kH/s</option>
          <option value="100000">MH/s</option>
          <option value="100000000">GH/s</option>
          <option value="100000000000">TH/s</option>
        </select>
      </div>
    </div>
  </div>
  <div class="overflow-x-auto relative rounded-lg">
    <table class="table-fixed w-full text-sm text-left text-gray-400">
      <thead class="text-xs uppercase bg-gray-700 text-gray-400">
      <tr>
        <th scope="col" class="px-6 py-3">
          {{ trans(COIN) }}
        </th>
        <th scope="col" class="px-6 py-3">
          {{ trans(ALGORITHM) }}
        </th>
        <th scope="col" class="px-6 py-3">
          {{ trans(PER_HOUR) }}
        </th>
        <th scope="col" class="px-6 py-3">
          {{ trans(PER_DAY) }}
        </th>
        <th scope="col" class="px-6 py-3">
          {{ trans(PER_WEEK) }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          class="border-b bg-gray-800 border-gray-700 hover:bg-gray-600"
          v-for="(coin, c_index) in coins"
          :key="c_index"
      >
        <th scope="row" class="px-6 py-4 font-medium text-white whitespace-nowrap">
          <a :href="coinPath + coin.slug"
             class="hover:underline"
          >
            <h3>{{ coin.name }}</h3>
          </a>
        </th>
        <th scope="row" class="px-6 py-4 font-medium text-white whitespace-nowrap">
          <a v-for="(algorithm, a_index) in coin.algorithms"
             :key="a_index"
             :href="algorithmPath + algorithm.slug"
             class="hover:underline"
          >
            {{ algorithm.name }}<span v-if="a_index !== Object.keys(coin.algorithms).length - 1">, </span>
          </a>
        </th>
        <td class="px-6 py-4">
          {{ coin.usdPerHash * obj.hash * obj.key }}
        </td>
        <td class="px-6 py-4">
          {{ coin.usdPerHash * 24 * obj.hash * obj.key }}
        </td>
        <td class="px-6 py-4">
          {{ coin.usdPerHash * 168 * obj.hash * obj.key }}
        </td>
      </tr>
      <tr class="border-b bg-gray-800 border-gray-700 hover:bg-gray-600"
          v-if="coins.length === 0">
        <td colspan="5"
            class="px-6 py-4 font-medium text-white whitespace-nowrap text-center">
          {{ trans(NO_FOUND, 'calculator') }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import {reactive} from "vue";
import {
  trans,
  NO_FOUND,
  HASHRATE_PLACEHOLDER,
  COIN,
  ALGORITHM,
  PER_HOUR,
  PER_WEEK,
  PER_DAY,
} from "../../translator";

defineProps({
  coins: Array,
  coinPath: String,
  algorithmPath: String,
});

const obj = reactive({
  hash: 10,
  key: 1,
});
</script>

<template>
  <div class="relative"
       :style="`height:${labels.length*250}px`">
    <Bar :data="chartConfig.data"
         :options="chartConfig.options"/>
  </div>
</template>

<script setup>
import {Bar} from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js'
import {computed, defineProps} from "vue";
import {
  trans,
  FREQ_OC,
  FREQ_B,
  BANDWIDTH,
  MEMORY_INTERFACE,
  POWER,
  PRICE,
} from "../../translator";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const props = defineProps({
  chartConfigProps: {
    type: Array,
    required: true,
    default: () => [],
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  labels: {
    type: Array,
    required: false,
    default: () => [],
  },
  suffix: {
    type: String,
    required: false,
    default: "",
  },
});

const chartConfig = computed(() => {
  return {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          beginAtZero: true,
          grid: {
            color: '#0d2fbc44' // Couleur de la grille pour l'axe des x
          }
        },
        y: {
          beginAtZero: true,
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              label += context.formattedValue + props.suffix;
              return label;
            }
          }
        },
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: props.title,
        }
      },
      indexAxis: 'y',
    },
    data: {
      datasets:
          props.chartConfigProps.map((item) => {
            return {
              label: item.label,
              data: item.data,
              backgroundColor: item.backgroundColor
            };
          }),
      labels: props.labels
    }
  }
});
</script>
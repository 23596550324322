import {Controller} from '@hotwired/stimulus';
import {
    trans,
    COPIED,
} from "../translator";

export default class extends Controller {
    static values = {
        text: String,
    };

    async copyText(event) {
        await navigator.clipboard.writeText(this.textValue);

        event.target.innerText = trans(COPIED, {}, 'benchmark');
    }
}
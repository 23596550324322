<template>
  <div class="space-y-8">
    <div>
      <div v-show="loading"
           v-html="loadingHTML"
      />
      <div class="grid md:grid-cols-2 gap-3">
        <div
            v-for="(hardware, key) in hardwaresData"
            :key="key"
            v-show="!loading"
            class="block py-4 px-8 rounded-2xl border-2 border-minerband-blue-700 space-y-4"
        >
          <div class="flex flex-col md:flex-row justify-center items-center">
            <img :src="hardware.hardware.image"
                 :alt="hardware.hardware.name"
                 class="w-24 lg:w-48 h-24 lg:h-48 aspect-square rounded-lg object-contain mx-auto hidden md:block bg-white">
            <div class="w-full space-y-4">
              <h2 class="text-white font-grandis capitalize text-center w-full lg:text-2xl font-bold leading-tight relative">
                <a class="hover:underline"
                   :href="hardware.hardware.url"
                >
                  {{ hardware.hardware.name }}
                </a>
              </h2>
              <a class="text-sm lg:text-base text-white text-center block hover:underline"
                 :href="hardware.chipset.url"
              >
                {{ hardware.chipset.name }}
              </a>
            </div>
          </div>
        </div>

        <button
            class="group flex justify-center items-center flex-col gap-4 py-4 px-8 rounded-2xl border-2 border-minerband-blue-700 hover:bg-minerband-blue-700/20 focus:bg-minerband-blue-700/40">
          <PlusCircleIcon
              @click="addCompare = !addCompare"
              v-if="!addCompare"
              class="w-12 h-12 text-minerband-blue-300 group-hover:text-minerband-blue-200 group-focus:text-minerband-blue-100"
          />
          <CompareAdd
              v-if="addCompare"
              :url-search="urlSearch"
              @submit="addCompareFunction"
              class="w-full"
          />
        </button>
      </div>
    </div>

    <div v-if="Object.keys(chartData).length !== 0"
         class="space-y-8">
      <Chart :chart-config-props="chartData['frequency']"
             :labels="[
                trans(FREQ_B),
                trans(FREQ_OC),
              ]"
             :title="`${trans(FREQ)} (MHz)`"
             suffix=" MHz"
      />
      <Chart :chart-config-props="chartData['bandwidth']"
             :labels="['']"
             :title="`${trans(BANDWIDTH)} (Go/s)`"
             suffix=" Go/s"
      />
      <Chart :chart-config-props="chartData['memoryInterface']"
             :labels="['']"
             :title="`${trans(MEMORY_INTERFACE)} (bits)`"
             suffix=" bits"
      />
      <Chart :chart-config-props="chartData['power']"
             :labels="['']"
             :title="`${trans(POWER)} (W)`"
             suffix="W"
      />
      <Chart :chart-config-props="chartData['price']"
             :labels="['']"
             :title="`${trans(PRICE)} ($)`"
             suffix="$"
      />
      <Chart :chart-config-props="chartData['score']"
             :labels="['']"
             :title="`${trans(SCORE)}`"
             suffix=" pts"
      />
    </div>
  </div>
</template>

<script>
import loading from "@/utils/loading";
import fetchHardware from "@/vue/services/fetchHardware";
import {PlusCircleIcon} from '@heroicons/vue/24/solid'
import Chart from "@/vue/components/Chart.vue";
import {BANDWIDTH, FREQ, FREQ_B, FREQ_OC, MEMORY_INTERFACE, POWER, PRICE, SCORE, trans} from "@/translator";
import randomColor from "randomcolor";
import CompareAdd from "@/vue/components/CompareAdd.vue";
import addCompareSlug from "@/vue/services/addCompareSlug";

export default {
  computed: {
    PRICE() {
      return PRICE
    }, POWER() {
      return POWER
    }, MEMORY_INTERFACE() {
      return MEMORY_INTERFACE
    }, BANDWIDTH() {
      return BANDWIDTH
    }, FREQ_B() {
      return FREQ_B
    }, FREQ_OC() {
      return FREQ_OC
    }, SCORE() {
      return SCORE
    },
    FREQ() {
      return FREQ
    },
  },
  components: {
    CompareAdd,
    Chart,
    PlusCircleIcon,
  },
  props: {
    urlResults: {
      type: String,
      required: true
    },
    urlSearch: {
      type: String,
      required: true
    },
    hardwaresProps: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      hardwares: this.$props.hardwaresProps,
      hardwaresData: [],
      hardwaresColors: {},
      chartData: {},
      loading: false,
      loadingHTML: loading,
      addCompare: false,
    }
  },
  mounted() {
    if (this.hardwares.length > 0) {
      this.getHardwares();
    } else {
      this.loading = false;
    }
  },
  methods: {
    trans,
    async getHardwares() {
      this.loading = true;

      this.chartData = [];

      const response = await fetchHardware(this.$props.urlResults + this.hardwares.join('-vs-'));
      const data = await response.json();

      this.hardwaresData = [];
      this.hardwaresColors = [];

      for (const hardware of data) {
        this.hardwaresData.push(hardware);

        this.hardwaresColors[hardware.hardware.slug] = randomColor({
          hue: '#849cf5'
        });
      }

      document.title = this.hardwaresData.map(item => item.hardware.name).join(' vs ');

      this.chartData["frequency"] = data.map((hardware) => (
          {
            label: hardware.hardware.name,
            data: [
              hardware.hardware.freqBase,
              hardware.hardware.freqOc,
            ],
            backgroundColor: this.hardwaresColors[hardware.hardware.slug],
          }
      ));
      this.chartData["bandwidth"] = data.map((hardware) => (
          {
            label: hardware.hardware.name,
            data: [
              hardware.hardware.bandwidth,
            ],
            backgroundColor: this.hardwaresColors[hardware.hardware.slug],
          }
      ));
      this.chartData["memoryInterface"] = data.map((hardware) => (
          {
            label: hardware.hardware.name,
            data: [
              hardware.hardware.memoryInterface,
            ],
            backgroundColor: this.hardwaresColors[hardware.hardware.slug],
          }
      ));
      this.chartData["power"] = data.map((hardware) => (
          {
            label: hardware.hardware.name,
            data: [
              hardware.hardware.power,
            ],
            backgroundColor: this.hardwaresColors[hardware.hardware.slug],
          }
      ));
      this.chartData["price"] = data.map((hardware) => (
          {
            label: hardware.hardware.name,
            data: [
              hardware.hardware.priceUSD,
            ],
            backgroundColor: this.hardwaresColors[hardware.hardware.slug],
          }
      ));
      this.chartData["score"] = data.map((hardware) => (
          {
            label: hardware.hardware.name,
            data: [
              hardware.hardware.score,
            ],
            backgroundColor: this.hardwaresColors[hardware.hardware.slug],
          }
      ));

      this.loading = false;
    },
    addCompareFunction(e, hardwareE) {
      const url = {name: 'Compare', params: {'slugs': addCompareSlug(this.hardwares, hardwareE)}};
      this.$router.push(url)

      fetch(`${this.$router.resolve(url).href}?fetch=1`);

      this.getHardwares();
      this.addCompare = false;
    }
  },
}


</script>

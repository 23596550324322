import {Controller} from "@hotwired/stimulus";
import loading from "@/utils/loading";

export default class extends Controller {
    static targets = ['message'];

    onSubmit(event) {
        event.preventDefault();

        this.oldButtonValue = event.currentTarget.innerHTML;

        this.submitForm(event.currentTarget);
    }

    async submitForm(currentTarget) {
        currentTarget.innerHTML = loading;
        currentTarget.disabled = true;

        const response = await fetch(this.element.action, {
            method: this.element.method,
            body: new FormData(this.element)
        });

        currentTarget.innerHTML = this.oldButtonValue;
        currentTarget.disabled = false;

        if (response.status === 201) {
            this.messageTarget.innerHTML = await response.text();
        } else {
            this.messageTarget.innerHTML = await response.text();
        }
    }
}